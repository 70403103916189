import * as React from "react";
import { Load } from "./Load";
import Link from "next/link";

const AnchorOrSpan = (props) => {
    if (props.href) {
        return <Link href={props.href} target={props.target}>{props.children}</Link>;
    } else {
        return <>{props.children}</>;
    }
  
};

export function Button(props) {

    return <>
        <AnchorOrSpan className={`${props.disabled && "disabled"} ${props.fill && "fill"}`} {...props} onClick={() => {
            if (!props.disabled && props.onClick) {
                props.onClick();
            }
        }}>
            <button onClick={() => {
                if (!props.href && props.onClick) {
                    props.onClick();
                }
            }} 
            className={`btn ${props.secondary ? "secondary-btn" : "primary-btn"} ${props.disabled && "disabled"} ${props.fill && "fill"} ${props.small && "small"} ${props.teal ? "teal" : ""}`}>
                <div className="content">{props.children}</div>
                <div className="loader"><Load size={20} /></div>
            </button>
        </AnchorOrSpan>
        <style jsx>
            {`
            .content{
                display:flex;
                align-items:center;
                gap:5px;
                opacity: ${props.load ? "0" : "100%"};
                justify-content: center;
            }
            .loader{
                position:absolute;
                opacity: ${props.load ? "100%" : "0"};
                top: 2px;
                left: 0;
                right: 0;
                margin: auto;
            }
            .btn > span{
                margin:0;
                padding:0;
                background:blue;
            }
            .btn {
                padding: 12px 10px;
                border-radius: calc(var(--border-radius) * 2);
                cursor:pointer;
                transition-property: all;
                transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                transition-duration: 50ms; 
                font-weight: bold;
                font-size: var(--font-size-3);
                line-height: 1;
                align-items:center;
                -webkit-user-select: none;
                -moz-user-select: none;
                user-select: none;
                color: white;
                position:relative;

            }
            .fill{
                width:100%!important;
                flex-grow:1;
            }
            .small {
                padding: ${props.fill ? "0" : "8px 36px"};
                font-size: var(--font-size-3);
                height: 3rem;
                min-height: 2rem;
            }
            .primary-btn{
                background-color: ${props.teal ? "var(--teal)" : "var(--pink)"};
                background-image: linear-gradient(45deg,${props.teal ? "var(--teal)" : "var(--pink)"}, ${props.teal ? "var(--teal)" : "var(--pink)"}, ${props.teal ? "var(--pink)" : "var(--teal)"});
                background-size: 300% 200%;
                background-position: 0% 0;
                color: ${props.teal ? "var(--black)" : "var(--white)"};
                transition: all 0.05s ease-in-out;
                border-top: 3px solid transparent;
                border-bottom: ${props.teal  ? "3px solid #ddd" : "3px solid #c83d6d"};
            }
            :global(.dark) .primary-btn{
                border-bottom: ${props.teal  ?  "3px solid #138c89" : "3px solid #c83d6d"};
            }
            .secondary-btn{
                background-color: ${props.teal ? "white" : "white"};
                background-color: ${props.teal ? "var(--covalent-dark-blue)" : "white"};
                color: ${props.teal ? "var(--teal)" : "var(--pink)"};
                border-top: 3px solid transparent;
                border-bottom: 3px solid #0000003b;
            }
            :global(.dark) .secondary-btn {
                background-color: ${props.teal ? "var(--covalent-dark-blue)" : "white"};
            }
            .secondary-btn:hover{
                background-color:${props.teal ? "var(--teal)" : "var(--pink)"};
                color: ${props.teal ? "black" : "white"};;
                border-bottom: 3px solid transparent;
                border-top: 3px solid transparent;
            }
            .primary-btn:hover{
                background-color: #E2437B;
                background-image: linear-gradient(90deg,${props.teal ? "var(--teal)" : "var(--pink)"}, ${props.teal ? "var(--pink)" : "var(--teal)"}, var(--teal));
                background-size: 300% 200%;
                background-position: 0% 0;
                color: white;
                border-bottom: 3px solid transparent;
                border-top: 3px solid transparent;
            }
            .disabled{
               opacity:50%;
            }
            .disabled:before {
                display: none;
            }
            .disabled:hover{
               opacity:40%;
               background: var(--gray-dark);
            }

            a {
                width:${!props.fill && "fit-content"};
                text-decoration: none !important;
            }
            `}
        </style></>;
}
