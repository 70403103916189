import { useEffect, useState } from "react";
import { Image, ResponsiveImageType } from "react-datocms";
import { getImages, getBlogMetaData } from "./helpers";
import Img from "./Img";

export default function BannerImage({ title, blog }) {
    const [image, setImage] = useState<string | null>("");
    const [responsiveImage, setResponsiveImage] = useState<ResponsiveImageType|null>();

    useEffect(() => {
        const allImages = getImages();
        const blogsMetaData = getBlogMetaData();
        const blogs = blogsMetaData.blogs;
        const currentBlog = blogs.filter((blog) => {
            return blog.title.toLowerCase() === title.toLowerCase();
        })[0];

        if (blog?.legacy) {
            if (currentBlog?.image && currentBlog.legacy) {
                const currentBlogImage = currentBlog?.image.split("/")[currentBlog.image.split("/").length - 1];
                const image = allImages.filter((i) => {
                    if (i.filename) {
                        return i.filename.split(".")[0].toLowerCase() === currentBlogImage.split(".")[0].toLowerCase();
                    }
                })[0]?.responsiveImage;

                if (image) {
                    setImage(null);
                    setResponsiveImage(image);
                } else {
                    setResponsiveImage(null);
                    setImage(currentBlog.image);
                }
            }
        } else {
            if (blog?.bannerimage?.responsiveImage) {
                setImage(null);
                setResponsiveImage(blog?.bannerimage.responsiveImage);
            } else {
                setResponsiveImage(null);
                setImage(blog.image);
            }
        }
    }, [blog?.bannerimage?.responsiveImage, blog.image, blog?.legacy, title]);
    return (
        <>
            <div className="image blog-banner-image">
                {
                    responsiveImage
                        ? <Image pictureClassName="image" data={responsiveImage} />
                        : <Img alt={`Blog Banner: ${title}`} title={title} src={image ? image : `https://og-generator-liart.vercel.app/api/param?title=${encodeURI(title)}`} />
                }
            </div>
            <style jsx>
                {`
                    @media screen and (max-width: 767px) {
                        .image {
                            width: auto;
                        }
                    }
                `}
            </style>
        </>
    );
}
