import React from "react";

import E from "./EventLibrary";
import router from "next/router";
import { isProduction } from "./helpers";
declare global {
    // eslint-disable-next-line no-unused-vars
    interface Window {
        rudderanalytics: any;
    }
}

export const navigateTo = (url: string) => {
    router.push(url, undefined, undefined);
};

export const rudderStackTrack = (action: string, properties: any) => {
    if (isProduction) {
        const R = window.rudderanalytics;
        if (R && R.track) {
            R.track(
                action,
                properties,
            );
        }
    } else {
        console.log("Event would have been sent to RudderStack", action, properties);
    }
};

export const rudderStackLocation = () => {
    if (isProduction) {
        const R = window.rudderanalytics;
        if (R && R.page) {
            R.page();
        }
    }
};

export const actionMap = {
    [E.docs_banner_clicked]: navigateTo,
};

export const trackActionWithId = (e: React.MouseEvent<HTMLElement>) => {
    const action = e.currentTarget.id.split("$")[0];
    const prop = e.currentTarget.id.split("$")[1];
    rudderStackTrack(action, { value: prop });
    actionMap[action](prop);
};
