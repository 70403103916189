import React from "react";
import { useEffect, useState } from "react";

export const Transition = ({theme}) => {

    const [active, setActive] = useState(false);

    const ref = React.useRef(theme);

    useEffect(() => {
        if (ref.current !== theme) {
            setActive(true);
        }
    }, [theme]);

    return <><div
        className={`transition ${active ? theme === 'dark' ? 'active-dark' : 'active-light' : ""}`}
    >

    </div>
    <style jsx>
        {`
            .transition {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1000;
                pointer-events: none;
            }

            .transition.active-dark {
                background-image: linear-gradient(0deg, #fff0 0%, var(--pink) 15%, var(--teal) 85%,  #fff0 100%);
                background-size: 100vw 200vh;
                background-repeat: no-repeat;
                animation: fade-dark 0.7s linear forwards;
            }

            .transition.active-light {
                background-image: linear-gradient(0deg, #fff0 0%,  var(--teal) 15%, var(--pink) 85%,   #fff0 100%);
                background-size: 100vw 200vh;
                background-repeat: no-repeat;
                animation: fade-light 0.7s linear forwards;
            }

            @keyframes fade-dark {
                0% {
                    background-position: 0vw -200vh;
                }
                50% {
                    background-position: 0vw 0vh;
                }
                100% {
                    background-position: 0vw 100vh;
                }
            }

            @keyframes fade-light {
                0% {
                    background-position: 0vw -200vh;
                }
                50% {
                    background-position: 0vw 0vh;
                }
                100% {
                    background-position: 0vw 100vh;
                }
            }

        `}
    </style></>;
};