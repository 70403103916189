import { useContext, useState } from "react";
import MenuContext from "./ContextProviders/MenuContext";
import React from "react";
import { useRouter } from "next/router";
import { Flex, IconButton } from "@radix-ui/themes";

const MobileOpenSideNav = () => {
    const menuContext = useContext<any>(MenuContext);
    const [isMobile, setIsMobile] = useState<boolean>(false);

    React.useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        checkMobile();

        window.addEventListener('resize', checkMobile);

        return () => {
            window.removeEventListener('resize', checkMobile);
        };
    }, []);

    const router = useRouter();

    React.useEffect(() => {
        if (router.isReady) {
            const handleRouteChange = () => {
                if (menuContext?.menuIsOpen) {
                    menuContext.handleChangeMenu();
                }
            };
            router.events.on('routeChangeComplete', handleRouteChange);
            return () => {
                router.events.off('routeChangeComplete', handleRouteChange);
            };
        }
    }, [router]);

    if (!isMobile) {
        return null;
    }

    if (menuContext.menuIsOpen) {
        return <Flex justify={"between"} style={{
            placeItems: "center",
            gap: "0.5rem",
            marginLeft: "-1rem",
            marginRight: "-1rem",
            marginTop: "0rem",
            marginBottom: "2rem",
            padding: "1rem 1rem",
            background: "var(--code-background)",
            borderRadius: "var(--border-radius)",
            height: "4rem",
            borderBottom: "1px solid var(--code-border)",
            width: "auto",
            position: "sticky",
            top: "0",
            zIndex: "10",
        }} >
            <div>Close Menu</div>
            <IconButton variant="outline" onClick={menuContext.handleChangeMenu}>
                <span className="material-symbols-rounded">close</span>
            </IconButton>
        </Flex>;
    }

    return <IconButton variant="outline" onClick={menuContext.handleChangeMenu}><span className="material-symbols-rounded">expand_more</span></IconButton>;
};

export default MobileOpenSideNav;
