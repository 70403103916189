import React, { useEffect, useState } from "react";
import { rudderStackTrack } from "./Track";
import ACTIONS from "./EventLibrary";
import { useRouter } from "next/router";
import { None, Option, Some } from "../components/helpers/option";
import ToggleButton from "./ToggleButton";


const Feedback = () => {
    const down = <svg width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.65953 14.4871L9.97333 14.4871C10.2802 14.4871 10.5292 14.7359 10.5292 15.0427L10.5292 19.4889C10.5126 20.0695 10.6844 20.6399 11.0191 21.1147C11.3537 21.5896 11.833 21.9434 12.3853 22.1231C12.6457 22.2074 12.9287 22.0894 13.0523 21.8453L17.3427 13.3755L20.3327 13.3755C20.775 13.3755 21.1991 13.1998 21.5117 12.8872C21.8243 12.5746 22 12.1505 22 11.7082L22 2.81577L22 2.81596C22 2.37364 21.8243 1.94954 21.5117 1.63695C21.1991 1.32435 20.775 1.14867 20.3327 1.14867L5.88299 1.14867C4.63809 1.14867 4.39357 2.0601 4.23784 2.67138C4.23784 2.76033 2.01472 11.5638 2.01472 12.7976L2.01491 12.7976C1.94978 13.2546 2.10032 13.7159 2.42224 14.0466C2.74435 14.3772 3.20135 14.54 3.65985 14.4872L3.65953 14.4871ZM20.8884 2.81601L20.8884 11.7079C20.8884 12.015 20.6396 12.2638 20.3326 12.2638L17.5538 12.2638L17.5538 2.25999L20.3326 2.25999C20.6396 2.25999 20.8884 2.50879 20.8884 2.81582L20.8884 2.81601ZM5.32683 2.9606C5.5157 2.26038 5.5825 2.26038 5.90481 2.26038L16.4421 2.2602L16.4421 12.6865L12.3072 20.8562C11.8734 20.538 11.6239 20.0267 11.6402 19.4891L11.6402 15.0429C11.6402 14.6007 11.4646 14.1766 11.152 13.864C10.8392 13.5512 10.4151 13.3756 9.97295 13.3756L3.65915 13.3756C3.23674 13.3756 3.09237 13.3756 3.09237 12.7976C3.10353 11.8417 5.02652 4.02762 5.30434 2.96055L5.32683 2.9606Z" fill="var(--text)"/>
    </svg>;

    const up = <svg width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.3405 9.51293H14.0267C13.7198 9.51293 13.4708 9.26414 13.4708 8.9573V4.51105C13.4874 3.93045 13.3156 3.36014 12.9809 2.88525C12.6463 2.41037 12.167 2.05661 11.6147 1.87687C11.3543 1.79258 11.0713 1.91055 10.9477 2.15469L6.65728 10.6245H3.66729C3.22497 10.6245 2.80087 10.8002 2.48828 11.1128C2.17569 11.4254 2 11.8495 2 12.2918V21.1842V21.184C2 21.6264 2.17566 22.0505 2.48828 22.3631C2.8009 22.6756 3.22498 22.8513 3.66729 22.8513H18.117C19.3619 22.8513 19.6064 21.9399 19.7622 21.3286C19.7622 21.2397 21.9853 12.4362 21.9853 11.2024H21.9851C22.0502 10.7454 21.8997 10.2841 21.5778 9.95344C21.2557 9.62277 20.7987 9.45997 20.3401 9.51279L20.3405 9.51293ZM3.11161 21.184V12.2921C3.11161 11.985 3.3604 11.7362 3.66744 11.7362H6.4462V21.74H3.66744C3.3604 21.74 3.11161 21.4912 3.11161 21.1842V21.184ZM18.6732 21.0394C18.4843 21.7396 18.4175 21.7396 18.0952 21.7396L7.5579 21.7398V11.3135L11.6928 3.14376C12.1266 3.46196 12.3761 3.97331 12.3598 4.5109V8.95714C12.3598 9.39927 12.5354 9.82337 12.848 10.136C13.1608 10.4488 13.5849 10.6244 14.027 10.6244H20.3409C20.7633 10.6244 20.9076 10.6244 20.9076 11.2024C20.8965 12.1583 18.9735 19.9724 18.6957 21.0394L18.6732 21.0394Z" fill="var(--text)"/>
    </svg>;

    const [useful, setUseful] = useState<Option<boolean>>(None);
    const [feedback, setFeedback] = useState<string>("");

    const router = useRouter();

    const handleRudderStack = (useful, action) => {
        rudderStackTrack(ACTIONS[`docs_feedback_${action}`], {
            value: {
                useful,
                path: router.asPath,
            }
        });
    };

    useEffect(()=>{
        if(router.isReady){
            setUseful(None);
            setFeedback("");
        }
    },[router.asPath]);

    return (
        <>
            <div className="feedback-container">
                <h2>Was this helpful?</h2>
                <div className="feedback-inputs">
                    {useful.match({
                        None: () => {
                            return <>
                                <div>
                                    <ToggleButton onClick={()=>{
                                        setUseful(new Some(true));
                                        handleRudderStack(true, "clicked");
                                    }}>
                                        {up} Yes
                                    </ToggleButton>
                                </div>
                                <div>
                                    <ToggleButton onClick={()=>{
                                        handleRudderStack(false, "clicked");
                                        setUseful(new Some(false));
                                    }}>
                                        {down} No
                                    </ToggleButton>
                                </div>
                            </>;
                        },
                        Some: (useful) => {
                            if(!useful){
                                return <div className="feedback">
                                    <textarea placeholder="(Optional) Tell us why?" onChange={(e) => {
                                        setFeedback(e.target.value);
                                    }}/>
                                    <div className={`feedback-button ${feedback.length <= 0 && "disabled"}`}onClick={()=>{
                                        handleRudderStack(false, "submit");
                                        setUseful(new Some(true));

                                    }}>Submit</div>
                                </div>;
                            }
                            return <p>Thank you for your feedback!</p>;
                        }
                    })}
  
                </div>


            </div>
            <style jsx>
                {`
                .disabled{
                    opacity: 50%;
                    pointer-events: none;
                }
                .feedback-container{
                    display:flex;
                    padding: 4rem;
                    height:200px;
                    gap:1rem;
                    flex-direction: ${useful.match({
            None: () => "row",
            Some: () => "column"
        })}

                }
                .feedback{
                   width:100%;
                }
                .feedback-inputs{
                    gap:1rem;
                    display:flex;
                }
                .feedback-button{
                    padding: 0.5rem 0.75rem;
                    height:fit-content;
                    border: 1px solid var(--code-border);
                    box-shadow: 0px 2px 0px 0px var(--code-border);
                    display:flex;
                    align-items:center;
                    justify-content:center;
                    gap:0.5rem;
                    width: fit-content;
                    background: var(--code-background);
                    border-radius: var(--border-radius);
                    cursor: pointer;
                }
                .feedback-button:hover{
                    background: var(--code-border);
                }
                input, textarea {
                    margin-bottom: 0.75rem;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    padding: 0.5rem 0.75rem;
                    gap: 10px;
                    width: 100%;
                    font-size:14px;
                    height: 48px;
                    background: #FFFFFF;
                    border: 1px solid var( --border-color);
                    border-radius: 4px;
                    flex: none;
                    order: 1;
                    align-self: stretch;
                    flex-grow: 0;
                  }
                  textarea {
                    height: 35px;
                  }

                  @media screen and (max-width: 767px) {
                    .feedback-container {
                        padding: 1rem;
                    }
                    h2 {
                        font-size: var(--font-size-4);
                    }
                  }
            `}
            </style>
        </>
    );
};

export default Feedback;