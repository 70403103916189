const DATO_TOKEN = "945bed5efa0c9b736e309d48d262d4";
const DATO_URL = "https://graphql.datocms.com/";

const PAGINATED_ENDPOINTS_MAP = {
    getErc20TransfersForWalletAddress: "BlockTransactionWithContractTransfers",
    getTokenHoldersV2ForTokenAddress: "TokenHolder",
    getBlockHeights: "BlockHeights",
    getLogEventsByAddress: "LogEvent",
    getLogEventsByTopicHash: "LogEvent",
    getChainCollections: "ChainCollectionItem",
    getTokenIdsForContractWithMetadata: "NftTokenContract",
};

const PAGINATED_ENDPOINTS_LINK_MAP = {
    getRecentTransactionsForAddress: "Transaction"
};

const PAGINATED_ENDPOINTS_BY_PAGE_MAP = {
    getRecentTransactionsForAddress: "getRecentTransactionsForAddressByPage",
    getChainCollections: "getChainCollectionsByPage",
    getTokenIdsForContractWithMetadata: "getTokenIdsForContractWithMetadataByPage",
    getBlockHeights: "getBlockHeightsByPage",
    getLogEventsByAddress: "getLogEventsByAddressByPage",
    getLogEventsByTopicHash: "getLogEventsByTopicHashByPage",
    getErc20TransfersForWalletAddress: "getErc20TransfersForWalletAddressByPage",
    getTokenHoldersV2ForTokenAddress: "getTokenHoldersV2ForTokenAddressByPage",
    "getTransactionsForBlockHashByPage": "getTransactionsForBlockHashByPage", // same name
    "getTimeBucketTransactionsForAddress": "getTimeBucketTransactionsForAddress", // same name
    "getTransactionsForAddressV3": "getTransactionsForAddressV3", // same name
};

const UNSUPPORTED_ENDPOINTS_LIST = [
    // not used or unreleased balance endpoints
    "getTokenHoldersChangesForTokenAddress",
    // "getNativeTokenBalance",
    // unused old nft endpoint
    "getNftExternalMetadataForContract",
    // unsupported transcation endpoints
    "getTransactionsForBlockByPage",
    // "getTimeBucketTransactionsForAddress",
    "getEarliestTimeBucketTransactionsForAddress",
    // "getTransactionsForAddressV3",
    "getTransactionsForAddress"
];

const NETWORKS_BODY = `
id
displayname
_status
chainname
chainid
testnet
description
blocktime
blockexplorer
phantom
nativegastoken
docsException
weight
url
nativegastoken
suspended
increment
integrationstage {
  stagesupport {
    classA
    classB
    classC
    humanDecodedTransactions
    nftAssetAndMetadataCache
    tokenHolders
    traces
  }
  stage
}
notes {
  value
  blocks
  links
}
appchainof {
  chainname
}
testnetchainof {
  chainname
}
imgsvg {
  url
}
imgblack {
  url      
}
notes {
  blocks
  links
  value
}
noteType
imgwhite {
  responsiveImage(imgixParams: {fit: crop, w: 200, h: 200, auto: format}) {
    src
    width
    height
    alt
    title
    base64
    bgColor
    sizes
  }      
}`;

const ENDPOINTS_BODY = `
  id
  _status
  class
  phantom
  realtime
  slug
  title
  grouping
  _allReferencingGuides {
    slug
    title
    seo {
      description
    }      
  }
  children {
    id
  }
  supportedchains {
    chainname
    chainid
  }
  allChainsSupported
  creditrate
  creditratemodel
  creditrateunit
  usecase
  description
  beta
  notes {
    blocks
    links
    value
  }
  noteType
  typescriptSdkSupport
`;

const GET_ALL_NETWORKS = (limit, skip) => `
query MyQuery {
  allBlockchains(orderBy: weight_ASC, first: ${limit}, skip: ${skip}) {
    ${NETWORKS_BODY}
  }
}`;

const GET_ALL_ENDPOINTS = `
query MyQuery {
  allApiendpoints(first: 100)  {
    ${ENDPOINTS_BODY}
  }
}`;

function get_all_networks(limit, skip) {
    const url = DATO_URL;

    return fetch(url, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${DATO_TOKEN}`
        },
        body: JSON.stringify({
            query: GET_ALL_NETWORKS(limit, skip),
        }),
    }).then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    }).catch(error => {
        console.error('Error:', error);
        return null;
    });
}

const GetAllNetworks = () => {
    let skip = 0;
    const limit = 100;
    let networks = [];

    const getNextBatch = () => {
        return get_all_networks(limit, skip).then(result => {
            if (result.errors) {
                return [];
            }

            networks = networks.concat(result.data?.allBlockchains);

            skip += limit;

            if (result.data?.allBlockchains.length < limit) {
                return [];
            }

            return getNextBatch(); // Recursively call the function to continue querying
        });
    };

    return getNextBatch().then(() => networks);
};

function GetAllEndpoints() {
    const url = DATO_URL;
    return fetch(url, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${DATO_TOKEN}`
        },
        body: JSON.stringify({
            query: GET_ALL_ENDPOINTS,
        }),
    }).then(response => {
        if (!response.ok) {
            throw new Error("Api Endpoints response was not ok");
        }
        return response.json();
    }).catch(error => {
        console.error('Error:', error);
        return null;
    });
}

function _m(s) {
    s = s.replace("swap_count24h", "swap_count_24h");
    s = s.replace("volume24h_quote", "volume_24h_quote");
    s = s.replace("fee24h_quote", "fee_24h_quote");
    s = s.replace("volume7d_quote", "volume_7d_quote");
    s = s.replace("pretty_volume24h_quote", "pretty_volume_24h_quote");
    s = s.replace("pretty_fee24h_quote", "pretty_fee_24h_quote");
    s = s.replace("pretty_volume7d_quote", "pretty_volume_7d_quote");
  
    s = s.replace("total_volume24h_quote", "total_volume_24h_quote");
    s = s.replace("pretty_total_volume24h_quote", "pretty_total_volume_24h_quote");
    s = s.replace("pretty_quote_rate24h", "pretty_quote_rate_24h");
    s = s.replace("quote_rate24h", "quote_rate_24h");
    s = s.replace("total_volume24h", "total_volume_24h");
    s = s.replace("token0", "token_0");
    s = s.replace("token1", "token_1");
    

    s = s.replace("swap_count24", "swap_count_24");
    s = s.replace("volume_timeseries7d", "volume_timeseries_7d");
    s = s.replace("volume_timeseries30d", "volume_timeseries_30d");
    s = s.replace("total_volume24h", "total_volume_24h");
    s = s.replace("liquidity_timeseries7d", "liquidity_timeseries_7d");
    s = s.replace("liquidity_timeseries30d", "liquidity_timeseries_30d");
    s = s.replace("price_timeseries7d", "price_timeseries_7d");
    s = s.replace("price_timeseries30d", "price_timeseries_30d");

    s = s.replace("volume_in24h", "volume_in_24h");
    s = s.replace("volume_out24h", "volume_out_24h");
    s = s.replace("volume_in7d", "volume_in_7d");
    s = s.replace("volume_out7d", "volume_out_7d");

    s = s.replace("image256", "image_256");
    s = s.replace("image512", "image_512");
    s = s.replace("image1024", "image_1024");
    s = s.replace("balance24h", "balance_24h");
    s = s.replace("quote24h", "quote_24h");
    s = s.replace("pretty_quote24h", "pretty_quote_24h");

    // special cases where we need to revert the token_0 and token_1 back to token0 and token1
    s = s.replace("pretty_price_of_token_0_in_token_1", "pretty_price_of_token0_in_token1");
    s = s.replace("pretty_price_of_token_1_in_token_0", "pretty_price_of_token1_in_token0");
    s = s.replace("price_of_token_0_in_token_1", "price_of_token0_in_token1");
    s = s.replace("price_of_token_1_in_token_0", "price_of_token1_in_token0");
    s = s.replace("price_of_token_0_in_token_1_description", "price_of_token0_in_token1_description");
    s = s.replace("price_of_token_1_in_token_0_description", "price_of_token1_in_token0_description");
    s = s.replace("price_of_token_0_in_quote_currency", "price_of_token0_in_quote_currency");
    s = s.replace("price_of_token_1_in_quote_currency", "price_of_token1_in_quote_currency");

    s = s.replace("amount0", "amount_0");
    s = s.replace("amount1", "amount_1");
    s = s.replace("amount0_in", "amount_0_in");
    s = s.replace("amount0_out", "amount_0_out");
    s = s.replace("amount1_in", "amount_1_in");
    s = s.replace("amount1_out", "amount_1_out");

    // special cases where we need to revert the amount_0 and amount_1 back to amount0 and amount1
    s = s.replace("sum_amount_0in", "sum_amount0in");
    s = s.replace("sum_amount_0out", "sum_amount0out");
    s = s.replace("sum_amount_1in", "sum_amount1in");
    s = s.replace("sum_amount_1out", "sum_amount1out");

    s = s.replace("pretty_total_fees24h", "pretty_total_fees_24h");
    s = s.replace("total_fees24h", "total_fees_24h");
    s = s.replace("total_swaps24h", "total_swaps_24h");
    s = s.replace("total_active_pairs7d", "total_active_pairs_7d");

    s = s.replace("volume_chart7d", "volume_chart_7d");
    s = s.replace("volume_chart30d", "volume_chart_30d");
    s = s.replace("liquidity_chart7d", "liquidity_chart_7d");
    s = s.replace("liquidity_chart30d", "liquidity_chart_30d");

    s = s.replace("transactions24h", "transactions_24h");

    return s;
}

module.exports = {
    GetAllNetworks,
    GetAllEndpoints,
    PAGINATED_ENDPOINTS_MAP,
    UNSUPPORTED_ENDPOINTS_LIST,
    PAGINATED_ENDPOINTS_LINK_MAP,
    PAGINATED_ENDPOINTS_BY_PAGE_MAP,
    _m
};