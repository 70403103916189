import { useRouter } from "next/router";
import React from "react";

export const useScrollReset = () => {
    const router = useRouter();

    // exceptions list
    const isApi = router.asPath.startsWith("/docs/api");
    const isGrk = router.asPath.startsWith("/products/goldrush");
    const isStaking = router.asPath.startsWith("/staking");

    const isChangeLog = router.asPath.startsWith(`/docs/unified-api/changelog/`);
    const containsHash = router.asPath.includes("#");

    React.useEffect(() => {
        const handleRouteChange = () => {
            if (!isApi && !isChangeLog && !containsHash && !isGrk && !isStaking) {
                const mainWrapper = document.getElementById("main-wrapper");
                if (mainWrapper) {
                    mainWrapper.scrollTop = 0;
                }
                window.scrollTo(0, 0);
            }
        };
        if (router.isReady) {
            router.events.on('routeChangeComplete', handleRouteChange);
            return () => {
                router.events.off('routeChangeComplete', handleRouteChange);
            };
        }
    }, [router.events, router]);
};
