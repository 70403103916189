import { createContext, useEffect, useState } from 'react';

export const useTheme = () => {
    const [theme, setTheme] = useState(() => {
        if (typeof window === 'undefined') return 'light';
        const storedTheme = localStorage.getItem('theme');
        return storedTheme ? storedTheme : 'light';
    });

    useEffect(() => {
        localStorage.setItem('theme', theme);
    }, [theme]);
    
    const toggleTheme = () => {
        setTheme(theme === 'light' ? 'dark' : 'light');
    };

    useEffect(() => {
        //wait 1.5 seconds
        setTimeout(() => {
            theme === "dark" ? document.body.classList.add("dark") : document.body.classList.remove("dark");
        }, 200);
        
    }
    ,[theme]);
    
    return {
        theme,
        toggleTheme,
        setTheme,
    };

};

export const ThemeContext = createContext<{ theme: string; toggleTheme: () => void; setTheme: (theme: string) => void; }>({
    theme: typeof window !== 'undefined' ? localStorage.getItem('theme') || 'light' : 'light',
    toggleTheme: () => {
        console.warn('no theme provider');
    },
    setTheme: (theme) => {
        console.warn(theme, 'no theme provider');
    }
});
