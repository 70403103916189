
const ToggleButton = (props) => {

    const { children, ...otherProps } = props;

    return <><div{ ...otherProps} className={"chain_name__input " + (props.selected && "selected")}>
        {children}
    </div>
    <style jsx>{`
            .chain_name__input {
                    padding: 0.5rem 0.75rem; 
                    border-radius: var(--border-radius);
                    border: 1px solid var(--code-border);
                    margin-right: auto;
                    cursor: pointer;
                    box-shadow: 0px 2px 0px 0px var(--code-border);
                    width: max-content;
                    background: var(--background);
                    display: flex;
                    place-items: center;
                    gap: 0.25rem;
                    font-size: var(--font-size-2);
            }

            :global(.dark .chain_name__input) {
                background: var(--black);
            }

            .chain_name__input.selected, .chain_name__input:hover:not(.no_interaction) {
                background: var(--code-border);
                box-shadow: none;
            }
    `}</style>
    </>;
};

export default ToggleButton;