import React from "react";
import { iTurnstileContext } from "../ContextProviders/TurnstileContext";

export const useTurnstile = ():iTurnstileContext => {
    const [highlighted, setHighlighted] = React.useState("");
    const [needsVerification, setNeedsVerification] = React.useState(true);
    const [isClosed, setClosed] = React.useState(false);
    const [validID, setValidId] = React.useState("");
    const handleClosed = () => {
        setClosed(true);
    };
    return {highlighted, setHighlighted, needsVerification, setNeedsVerification, isClosed, setClosed, handleClosed, setValidId, validID};
};
