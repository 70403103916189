/* eslint-disable no-unused-vars */
enum ACTIONS {
    landing_taxation_submit = "landing_taxation_submit",
    landing_taxation_usercreated = "landing_taxation_usercreated",
    docs_banner_clicked = "docs_banner_clicked",
    docs_signupcta_clicked = "docs_signupcta_clicked",
    docs_homecard_clicked = "docs_homecard_clicked",
    docs_networkgrid_clicked = "docs_networkgrid_clicked",
    docs_pricingcta_clicked = "docs_pricingcta_clicked",
    docs_networkcta_clicked = "docs_networkcta_clicked",
    docs_discordcta_clicked = "docs_discordcta_clicked",
    platform_faucet_submitted = "platform_faucet_submitted",
    platform_faucet_success = "platform_faucet_success",
    platform_faucet_failure = "platform_faucet_failure",
    platform_support_attempt = "platform_support_attempt",
    platform_support_failed = "platform_support_failed",
    platform_support_success = "platform_support_success",
    platform_signup_success = "platform_signup_success",
    platform_signup_attempt = "platform_signup_attempt",
    platform_signup_failed = "platform_signup_failed",
    docs_feedback_clicked = "docs_feedback_clicked",
    docs_feedback_submit = "docs_feedback_submit"

}

export default ACTIONS;
