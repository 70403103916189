/* eslint-disable */

import * as React from "react";
import { Button } from "./Button";
import { Card, Flex } from "@radix-ui/themes";

declare global {
    interface Window {
        cfields: any;
        _show_thank_you: any;
        _load_script: any;
        _show_error: any;
        _form_callback: any;
        vgo: any;
    }

    const visitorGlobalObjectAlias: any;
}

export function NewsLetter({handleToast, teal = false}) {

    React.useEffect(() => {
        window.cfields = [];
        window._show_thank_you = function(id, message, trackcmp_url, email) {
            const form: any = document.getElementById("_form_" + id + "_");
            const thank_you: any = form.querySelector("._form-thank-you");
            const qr: any = form.querySelector("._form-content");
            qr.style.display = "none";
            handleToast({
                title: "Successfully signed up for var(--teal)."
            });
            // thank_you.innerHTML = message;
            // thank_you.style.display = "block";
            const vgoAlias = typeof visitorGlobalObjectAlias === "undefined" ? "vgo" : visitorGlobalObjectAlias;
            const visitorObject: any = window[vgoAlias];
            if (email && typeof visitorObject !== "undefined") {
                visitorObject("setEmail", email);
                visitorObject("update");
            } else if (typeof (trackcmp_url) != "undefined" && trackcmp_url) {
                // Site tracking URL to use after inline form submission.
                _load_script(trackcmp_url);
            }
            if (typeof window._form_callback !== "undefined") {
                window._form_callback(id);
            }
        };
        window._show_error = function(id, message, html) {
            const form = document.getElementById("_form_" + id + "_"), err = document.createElement("div");
           if (form) {
               const button = form.querySelector("button");
               const old_error = form.querySelector("._form_error");
               if (old_error && old_error.parentNode) {
                   old_error.parentNode.removeChild(old_error);
               }
               err.innerHTML = message;
               err.className = "_error-inner _form_error _no_arrow";
               const wrapper = document.createElement("div");
               wrapper.className = "_form-inner";
               wrapper.appendChild(err);
               if (button && button.parentNode) {
                   button.parentNode.insertBefore(wrapper, button);
                   const isub: any = document.querySelector("[id^=\"_form\"][id$=\"_submit\"]")
                   isub.disabled = false;
                   if (html) {
                       const div = document.createElement("div");
                       div.className = "_error-html";
                       div.innerHTML = html;
                       err.appendChild(div);
                   }
               }
           }
        };
        const _load_script = function(url, callback?) {
            let head = document.querySelector("head"), script: any = document.createElement("script"), r = false;
            script.type = "text/javascript";
            script.src = url;
            if (callback) {
                script.onload = script.onreadystatechange = function() {
                    if (!r && (!this.readyState || this.readyState == "complete")) {
                        r = true;
                        callback();
                    }
                };
            }
            if (head) {
                head.appendChild(script);
            }
        };
        (function() {
            if (window.location.search.search("excludeform") !== -1) {
                return false;
            }
            const getCookie = function(name) {
                const match = document.cookie.match(new RegExp("(^|; )" + name + "=([^;]+)"));
                return match ? match[2] : null;
            };
            const setCookie = function(name, value) {
                const now = new Date();
                const time = now.getTime();
                const expireTime = time + 1000 * 60 * 60 * 24 * 365;
                now.setTime(expireTime);
                document.cookie = name + "=" + value + "; expires=" + now + ";path=/; Secure; SameSite=Lax;";// cannot be HttpOnly
            };
            const addEvent = function(element, event, func) {
                if (element.addEventListener) {
                    element.addEventListener(event, func);
                } else {
                    const oldFunc = element["on" + event];
                    element["on" + event] = function() {
                        oldFunc.apply(this, arguments);
                        func.apply(this, arguments);
                    };
                }
            };
            const _removed = false;
            const form_to_submit: any = document.getElementById("_form_29_");
            let allInputs: any = form_to_submit.querySelectorAll("input, select, textarea"), tooltips: any[] = [], submitted = false;

            const getUrlParam = function(name) {
                const params = new URLSearchParams(window.location.search);
                return params.get(name) || false;
            };

            for ( let i = 0; i < allInputs.length; i++) {
                const regexStr = "field\\[(\\d+)\\]";
                const results = new RegExp(regexStr).exec(allInputs[i].name);
                if (results != undefined) {
                    allInputs[i].dataset.name = window.cfields[results[1]];
                } else {
                    allInputs[i].dataset.name = allInputs[i].name;
                }
                const fieldVal = getUrlParam(allInputs[i].dataset.name);

                if (fieldVal) {
                    if (allInputs[i].dataset.autofill === "false") {
                        continue;
                    }
                    if (allInputs[i].type == "radio" || allInputs[i].type == "checkbox") {
                        if (allInputs[i].value == fieldVal) {
                            allInputs[i].checked = true;
                        }
                    } else {
                        allInputs[i].value = fieldVal;
                    }
                }
            }

            const remove_tooltips = function() {
                for ( let i = 0; i < tooltips.length; i++) {
                    tooltips[i].tip.parentNode.removeChild(tooltips[i].tip);
                }
                tooltips = [];
            };
            const remove_tooltip = function(elem) {
                for ( let i = 0; i < tooltips.length; i++) {
                    if (tooltips[i].elem === elem) {
                        tooltips[i].tip.parentNode.removeChild(tooltips[i].tip);
                        tooltips.splice(i, 1);
                        return;
                    }
                }
            };
            const create_tooltip = function(elem, text) {
                const tooltip = document.createElement("div"), arrow = document.createElement("div"), inner = document.createElement("div"), new_tooltip: any = {};
                if (elem.type != "radio" && elem.type != "checkbox") {
                    tooltip.className = "_error";
                    arrow.className = "_error-arrow";
                    inner.className = "_error-inner";
                    inner.innerHTML = text;
                    tooltip.appendChild(arrow);
                    tooltip.appendChild(inner);
                    elem.parentNode.appendChild(tooltip);
                } else {
                    tooltip.className = "_error-inner _no_arrow";
                    tooltip.innerHTML = text;
                    elem.parentNode.insertBefore(tooltip, elem);
                    new_tooltip.no_arrow = true;
                }
                new_tooltip.tip = tooltip;
                new_tooltip.elem = elem;
                tooltips.push(new_tooltip);
                return new_tooltip;
            };
            const resize_tooltip = function(tooltip) {
                const rect = tooltip.elem.getBoundingClientRect();
                const doc = document.documentElement, scrollPosition = rect.top - ((window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0));
                if (scrollPosition < 40) {
                    tooltip.tip.className = tooltip.tip.className.replace(/ ?(_above|_below) ?/g, "") + " _below";
                } else {
                    tooltip.tip.className = tooltip.tip.className.replace(/ ?(_above|_below) ?/g, "") + " _above";
                }
            };
            const resize_tooltips = function() {
                if (_removed) {
                    return;
                }
                for ( let i = 0; i < tooltips.length; i++) {
                    if (!tooltips[i].no_arrow) {
                        resize_tooltip(tooltips[i]);
                    }
                }
            };
            const validate_field = function(elem: any, remove?) {
                let tooltip = null, value = elem.value, no_error = true;
                remove ? remove_tooltip(elem) : false;
                if (elem.type != "checkbox") {
                    elem.className = elem.className.replace(/ ?_has_error ?/g, "");
                }
                if (elem.getAttribute("required") !== null) {
                    if (elem.type == "radio" || (elem.type == "checkbox" && /any/.test(elem.className))) {
                        const elems: any[] = form_to_submit.elements[elem.name];
                        if (!(elems instanceof NodeList || elems instanceof HTMLCollection) || elems.length <= 1) {
                            no_error = elem.checked;
                        } else {
                            no_error = false;
                            for ( let i = 0; i < elems.length; i++) {
                                if (elems[i].checked) {
                                    no_error = true;
                                }
                            }
                        }
                        if (!no_error) {
                            tooltip = create_tooltip(elem, "Please select an option.");
                        }
                    } else if (elem.type == "checkbox") {
                        let elems = form_to_submit.elements[elem.name], found = false, err: any[] = [];
                        no_error = true;
                        for ( let i = 0; i < elems.length; i++) {
                            if (elems[i].getAttribute("required") === null) {
                                continue;
                            }
                            if (!found && elems[i] !== elem) {
                                return true;
                            }
                            found = true;
                            elems[i].className = elems[i].className.replace(/ ?_has_error ?/g, "");
                            if (!elems[i].checked) {
                                no_error = false;
                                elems[i].className = elems[i].className + " _has_error";
                                err.push("Checking %s is required".replace("%s", elems[i].value));
                            }
                        }
                        if (!no_error) {
                            tooltip = create_tooltip(elem, err.join("<br/>"));
                        }
                    } else if (elem.tagName == "SELECT") {
                        let selected = true;
                        if (elem.multiple) {
                            selected = false;
                            for ( let i = 0; i < elem.options.length; i++) {
                                if (elem.options[i].selected) {
                                    selected = true;
                                    break;
                                }
                            }
                        } else {
                            for ( let i = 0; i < elem.options.length; i++) {
                                if (elem.options[i].selected && (!elem.options[i].value || (elem.options[i].value.match(/\n/g)))) {
                                    selected = false;
                                }
                            }
                        }
                        if (!selected) {
                            elem.className = elem.className + " _has_error";
                            no_error = false;
                            tooltip = create_tooltip(elem, "Please select an option.");
                        }
                    } else if (value === undefined || value === null || value === "") {
                        elem.className = elem.className + " _has_error";
                        no_error = false;
                        tooltip = create_tooltip(elem, "This field is required.");
                    }
                }
                if (no_error && elem.name == "email") {
                    if (!value.match(/^[\+_a-z0-9-'&=]+(\.[\+_a-z0-9-']+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i)) {
                        elem.className = elem.className + " _has_error";
                        no_error = false;
                        tooltip = create_tooltip(elem, "Enter a valid email address.");
                    }
                }
                if (no_error && /date_field/.test(elem.className)) {
                    if (!value.match(/^\d\d\d\d-\d\d-\d\d$/)) {
                        elem.className = elem.className + " _has_error";
                        no_error = false;
                        tooltip = create_tooltip(elem, "Enter a valid date.");
                    }
                }
                tooltip ? resize_tooltip(tooltip) : false;
                return no_error;
            };
            const needs_validate = function(el) {
                if (el.getAttribute("required") !== null) {
                    return true;
                }
                if (el.name === "email" && el.value !== "") {
                    return true;
                }
                return false;
            };
            const validate_form = function(e?) {
                let err = form_to_submit.querySelector("._form_error"), no_error = true;
                if (!submitted) {
                    submitted = true;
                    for ( let i = 0, len = allInputs.length; i < len; i++) {
                        const input: any = allInputs[i];
                        if (needs_validate(input)) {
                            if (input.type == "text" || input.type == "number" || input.type == "time") {
                                addEvent(input, "blur", function() {
                                    this.value = this.value.trim();
                                    validate_field(this, true);
                                });
                                addEvent(input, "input", function() {
                                    validate_field(this, true);
                                });
                            } else if (input.type == "radio" || input.type == "checkbox") {
                                (function(el) {
                                    const radios = form_to_submit.elements[el.name];
                                    for ( let i = 0; i < radios.length; i++) {
                                        addEvent(radios[i], "click", function() {
                                            validate_field(el, true);
                                        });
                                    }
                                })(input);
                            } else if (input.tagName == "SELECT") {
                                addEvent(input, "change", function() {
                                    validate_field(this, true);
                                });
                            } else if (input.type == "textarea") {
                                addEvent(input, "input", function() {
                                    validate_field(this, true);
                                });
                            }
                        }
                    }
                }
                remove_tooltips();
                for ( let i = 0, len = allInputs.length; i < len; i++) {
                    const elem = allInputs[i];
                    if (needs_validate(elem)) {
                        if (elem.tagName.toLowerCase() !== "select") {
                            elem.value = elem.value.trim();
                        }
                        validate_field(elem) ? true : no_error = false;
                    }
                }
                if (!no_error && e) {
                    e.preventDefault();
                }
                resize_tooltips();
                return no_error;
            };
            addEvent(window, "resize", resize_tooltips);
            addEvent(window, "scroll", resize_tooltips);
            const _form_serialize = function(form) {
                if (!form || form.nodeName !== "FORM") {
                    return;
                }  let i, j, q: any[] = []; for (i = 0; i < form.elements.length; i++) {
                    if (form.elements[i].name === "") {
                        continue;
                    } switch (form.elements[i].nodeName) {
                        case "INPUT": switch (form.elements[i].type) {
                            case "text": case "number": case "date": case "time": case "hidden": case "password": case "button": case "reset": case "submit": q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].value)); break; case "checkbox": case "radio": if (form.elements[i].checked) {
                                q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].value));
                            } break; case "file": break;
                        } break; case "TEXTAREA": q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].value)); break; case "SELECT": switch (form.elements[i].type) {
                            case "select-one": q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].value)); break; case "select-multiple": for (j = 0; j < form.elements[i].options.length; j++) {
                                if (form.elements[i].options[j].selected) {
                                    q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].options[j].value));
                                }
                            } break;
                        } break; case "BUTTON": switch (form.elements[i].type) {
                            case "reset": case "submit": case "button": q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].value)); break;
                        } break;
                    }
                } return q.join("&");
            };
            const form_submit = function(e) {
                e.preventDefault();
                if (validate_form()) {
                // use this trick to get the submit button & disable it using plain javascript
                    const form: any = document.querySelector("#_form_29_submit")
                    form.disabled = true;
                    const _form: any = document.getElementById("_form_29_");
                    const formSerialized =  _form_serialize(_form);
                    if (formSerialized) {
                        const serialized = formSerialized.replace(/%0A/g, "\\n");
                        const err = form_to_submit.querySelector("._form_error");
                        err ? err.parentNode.removeChild(err) : false;
                        _load_script("https://covalenthq.activehosted.com/proc.php?" + serialized + "&jsonp=true");
                    }
                }
                return false;
            };
            addEvent(form_to_submit, "submit", form_submit);
        })();
    });

    return <div className="form">
        <Card variant="ghost" className="gradient-card-background animate-in" size="4" mb="8">
            <Flex justify="between" wrap="wrap">
            <Flex gap="3" direction="column">
                <h3>
                    Get the Covalent newsletter.
                </h3>
                <p>Stay updated on our latest blockchain data API product launches, network updates, research insights, token economics related news, and more!</p>
            </Flex>
            <div className="_form_9" />
            <form method="POST" action="https://covalenthq.activehosted.com/proc.php" id="_form_29_"
                className="_form _form_29 _inline-form  _dark  flex-center" noValidate>
                <input type="hidden" name="u" value="29" />
                <input type="hidden" name="f" value="29" />
                <input type="hidden" name="s" />
                <input type="hidden" name="c" value="0" />
                <input type="hidden" name="m" value="0" />
                <input type="hidden" name="act" value="sub" />
                <input type="hidden" name="v" value="2" />
                <input type="hidden" name="or" value="d98952d7bb41a09e72490e88ecaa7b8d" />
                <div className="_form-content">
                    <div className="_form_element _x33092588 _full_width ">
                        <label htmlFor="email" className="_form-label">
                                Email
                        </label>
                        <div className="_field-wrapper">
                            <input autoComplete="email" className="input" type="text" id="email" name="email" placeholder=""
                                required />
                        </div>
                    </div>
                  
                    <div className="_button-wrapper _full_width">
                        <Button teal={teal} fill={"true"}  id="_form_29_submit" className="_submit _button-wrapper" type="submit">
                            Subscribe
                        </Button>
                    </div>
                </div>
                <div className="_form-thank-you" />
            </form>
            </Flex>
        </Card>
        <style jsx>
                {`
                 
                  ._form{
                    padding: 0;
                  }
                  ._button-wrapper{
                    display:flex;
                    align-items:flex-end;
                  }
                  ._form_element{
                    display:flex;
                    flex-direction:column;
                    gap:10px;
                  }
                  p {
                    font-size:var(--font-size-4);
                    line-height: var(--line-height-4);
                    color: var(--text);
                  }
                  h3 {
                    font-weight: var(--font-weight-bold);
                    font-size: var(--font-size-5);
                    margin-top: 0;
                  }
                  ._form-content{
                    display:flex;
                    flex-wrap: wrap;
                    gap:1rem;
                    width:100%;
                    justify-content:space-between;
                  }
                  .form {
                    width: 100%;
                  }
                  .text-pink {
                    color: ${teal && "var(--teal)"};
                  }
                  p {
                    max-width: var(--text-max-width-2);
                  }
                  .description {
                    max-width:40rem;
                    }
                  .input {
                    padding: 8px 12px;
                    -webkit-border-radius: var(--border-radius);
                    -moz-border-radius: var(--border-radius);
                    border-radius: var(--border-radius);
                    border: 1px solid lightgrey;
                    margin-left: auto;
                  }
                  ._form_29{
                    grid-column: span 2 / span 2;
                  }
                  ._form_9{
                    display: none;
                  }
                  .wrapper button {
                    background: ${teal ? "var(--teal)" : "var(--pink)"};
                    padding: 12px 12px;
                    border-radius: 4px;
                    cursor:pointer;
                    font-weight:var(--font-weight-bold);
                    transition-property: all;
                    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                    transition-duration: 150ms; 
                    font-size: var(--font-size-3);
                    line-height: 1;
                    outline: none;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    user-select: none;
                    color: white;
                    transition-property: all;
                    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                    transition-duration: 150ms;
                  }
                  }
                `}
            </style>
    </div>;
}


