import React from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { capitalizeFirstLetter, API_REFERENCE_ROUTE } from "./helpers";

const TopNav: React.FC<any> = ({ children }) => {
    const router = useRouter();
    const categories = ["company", "ecosystem", "increment", "product"];
    const topRouteParts = router.pathname.split("/"), topRoute = `/${topRouteParts[1]}${topRouteParts[2] ? "/" + topRouteParts[2] + "/" + topRouteParts[3] : ""}`;
    const routerPath = router.asPath;
    const navLinks: any = [];

    if (topRouteParts[1] === "blog") {
        navLinks.push({
            title: "Blog Home",
            href: "/blog/"
        });
        categories.forEach((category) => {
            navLinks.push({ title: capitalizeFirstLetter(category), href: "/blog/category/" + category + "/" });
        });
    } else if (topRouteParts[1] === "docs") {
        navLinks.push(...[{
            title: "Docs Home",
            href: "/docs/"
        },
        {
            title: "Supported Networks",
            href: "/docs/networks/"
        },
        {
            title: "Unified API",
            href: "/docs/unified-api/"
        },
        {
            title: "Covalent Network",
            href: "/docs/covalent-network/"
        },
        {
            title: "Increment",
            href: "/docs/increment/"
        }
        ]);
    }


    return (
        <>
            <div className={capitalizeFirstLetter(topRouteParts[1]) !== "Lp" ? "header" : "lp-header"}>
                <div className="headerTop">
                    <section>{children}</section>
                </div>
                <div className="nav">
                    {
                        <>
                            <span className="nav-section"> {capitalizeFirstLetter(topRouteParts[1])}: </span> {navLinks.map((navLink) => {

                                const isCurrentRoute = routerPath === navLink.href;
                                const isTopRouteUnderNavLink = (navLink.href !== "/docs/" && navLink.href !== "/blog/") && topRoute.startsWith(navLink.href);
                                const active = isCurrentRoute || isTopRouteUnderNavLink;

                                return (<span key={navLink.title} className={`${active ? "active" : "not-active"}`}>
                                    <Link title={navLink.title} href={navLink.href}>{navLink.title}</Link>
                                </span>);
                            })}
                        </>
                    }
                    <span className="spacer"/>
                    {
                        router.asPath.startsWith("/docs") && <span className={(topRoute.startsWith(`/docs/${API_REFERENCE_ROUTE}`) ? "active" : "not-active") + " rightNav"}>
                            <Link id="api-docs" href="/docs/api/">API Reference</Link>
                        </span>
                    }
                </div>
                <style jsx>
                    {`
                      .header {
                        top: 0;
                        width: 100%;
                        position: relative;
                        z-index: 0;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        border-bottom: 1px solid var(--code-border);
                      }
                      
                      .lp-header {
                        top: 0;
                        width: 100%;
                        position: relative;
                        z-index: 100;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                      }
                      
                      .headerTop {
                        display: flex;
                        align-items:center;
                        width: 100%;
                        flex-direction: row;
                      }

                      .header :global(a) {
                        text-decoration: none;
                      }
                      
                      .nav {
                        width: 100vw;
                        padding: 0 1.5rem;
                        flex-direction: row;
                        gap: 2.25rem;
                        letter-spacing: -.005rem;
                        overflow: hidden;
                      }

                      .nav .top-nav-tab{
                        color: var(--text);
                      }
                      
                      .spacer {
                        flex-grow: 1;
                      }

                      span {
                        padding-bottom: 10px;
                        // margin-bottom: -1px;
                      }

                      span.active > :global(a) {
                        color: var(--text);
                        font-weight: var(--font-weight-light);
                        line-height: 1;
                        border-radius: 4px;
                        font-size: var(--font-size-3);
                        font-weight: var(--font-weight-medium);
                      }

                      span.not-active > :global(a) {
                        color: var(--text);
                        line-height: 1;
                        border-radius: 4px;
                        font-size: var(--font-size-3);
                        font-weight: var(--font-weight-medium);
                        opacity: 0.7;
                      }

                      span.active {
                        border-bottom: 2px solid;
                        border-bottom-color: #FF4C8B;
                      }

                      span :global(a) {
                        color: #70767c;
                        font-weight: var(--font-weight-light);
                        font-size: 0.85rem;
                      }

                      span :global(a):hover {
                        opacity: 1;
                      }

                      span.not-active > :global(a):hover {
                        color: var(--text);
                      }

                      section {
                        display: flex;
                        gap: 1rem;
                        padding: 0;
                      }

                      .logo-title {
                        color: var(--text);
                        margin-left: 0.35rem;
                        font-size: 1.25rem;
                      }

                      .nav-section {
                        color: var(--text);
                        font-size:  var(--font-size-3);
                        display: none
                      }

                      @media screen and (max-width: 767px) {
                        .headerTop {
                          flex-wrap: wrap
                        }
                        .header {
                          padding-right: 0rem;
                          padding-left: 0rem;
                          width: 100vw;
                        }
                        .spacer {
                          display: none;
                        }
                        .header .nav {
                          overflow-x: auto;
                          overflow-y: hidden;
                          width: 100%;
                          gap: 1.5rem !important;
                          padding-left: 1rem;
                          padding-right: 1rem;
                        }

                        .header .nav::-webkit-scrollbar-track {
                          background: transparent;
                        }

                        .header .nav::-webkit-scrollbar-thumb {
                          background: transparent;
                        }
                      }
                    `}
                </style>
            </div>

        </>
    );
};


export default TopNav;