import React from "react";
import Lottie from "react-lottie";

interface AnimationPlayerProps {
    width: number;
    height: number;
    animation: any;
}

export const JsonAnimator = (props: AnimationPlayerProps) => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: props.animation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return <><div className="flex-center loader"><Lottie options={defaultOptions}
        height={props.height}
        width={props.width}
        isStopped={false}
        isPaused={false} />
    </div>
    </>;
};

export default JsonAnimator;
