import { getStaff } from "./helpers";
import { Flex, Avatar as _Avatar } from "@radix-ui/themes";

export function Avatar({ blog }) {

    let author;
    if (blog.legacy) {
        author = getStaff(blog.author ? blog.author : "ganesh");
    } else {
        author = blog.author;
    }

    return (
        <>
            <Flex gap="3" align="center">
                {
                    author && author.headshot ?
                        <_Avatar size="4" alt={author?.name ? author.name : "Ganesh Swami"} fallback={author?.name.slice(0,1)} src={author.headshot.responsiveImage.src}/>
                        :
                        <div className="avatar" />
                }
                <Flex direction="column" gap="0">
                    <div>{author?.name ? author.name : "Ganesh Swami"}</div>
                    <div>{author?.title ? author.title : "CEO"}</div>
                </Flex>
            </Flex>
        </>
    );
}
