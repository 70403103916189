import React, { useEffect, useRef } from "react";

const Img = ({src, title, alt}) => {
    const imageRef = useRef<HTMLImageElement>(null);

    const addDefaultSrc = (ev) => {
        ev.target.src = `https://og-generator-liart.vercel.app/api/param?title=${encodeURI(title)}`;
    };

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const img = imageRef.current;
                    if (img) {
                        img.src = img.dataset.src || "";
                        observer.unobserve(img);
                    }
                }
            });
        });

        if (imageRef.current) {
            observer.observe(imageRef.current);
        }

        return () => {
            if (imageRef.current) {
                observer.unobserve(imageRef.current);
            }
        };
    }, []);

    return <img onError={addDefaultSrc} ref={imageRef} data-src={src} alt={alt} />;
};

export default Img;
