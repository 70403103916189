import { createContext } from 'react';

export interface iTurnstileContext {
    setHighlighted: Function;
    highlighted: string;
    needsVerification: boolean;
    setNeedsVerification: Function;
    setClosed: Function;
    isClosed: boolean;
    handleClosed: Function;
    validID: string;
    setValidId: Function;
}

const TurnstileContext = createContext<iTurnstileContext>({
    setHighlighted: () => null,
    highlighted: "",
    needsVerification: true,
    setNeedsVerification: () => null,
    setClosed: () => null,
    isClosed: false,
    handleClosed: () => null,
    validID: "", 
    setValidId: () => null
});

export default TurnstileContext;
