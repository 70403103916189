import React from "react";
import * as Toast from "@radix-ui/react-toast";
import { Icon } from "./Icon";

interface ToasterProps {
    open: boolean;
    setOpen: Function;
    action?: any;
    title: any;
    description: any;

}

const Toaster = (props: ToasterProps) => {

    return (
        <>
            <Toast.Root className="ToastRoot" open={props.open} >
                <div className="cross" onClick={() => {
                    props.setOpen(false);
                }}>
                    <Icon icon="cross" size="0.7" />
                </div>
                <Toast.Title className="ToastTitle">{props.title}</Toast.Title>
                <Toast.Description asChild>
                    <div className="ToastDescription">
                        {props.description}
                    </div>
                </Toast.Description>
                <Toast.Action className="ToastAction" asChild altText="Goto schedule to undo">
                    {props.action}
                </Toast.Action>
            </Toast.Root>
            <style jsx>
                {`
                .cross{
                    position:absolute;
                    top:12px;
                    right:10px;
                    cursor:pointer;
                }
            `}
            </style>
        </>

    );

};

export default Toaster;
