import React from "react";
import Link from "next/link";
import { Avatar } from "./Avatar";

export default function TableOfContents({toc, blog, showAuthor = true}) {
    if (!toc) {
        return null;
    }

    const items = toc.filter(
        (item) => item.id && (item.level === 2 || item.level === 3)
    );

    return (
        <nav className="toc">
            {((blog && showAuthor) && <div className="avatar-cont">
                <Avatar blog={blog}/>
            </div>)}
            {items.length >= 1 && <h3>On this page</h3>}
            <ul className="flex column">
                {items.map((item) => {
                    const href = `#${item.id}`;
                    const active =
            typeof window !== "undefined" && window.location.hash === href;
                    return (
                        <li
                            key={item.title}
                            className={[
                                active ? "active" : undefined,
                                item.level === 3 ? "padded" : undefined,
                            ]
                                .filter(Boolean)
                                .join(" ")}
                        >
                            <div className="flex">
                                {(item.level === 3 && <div>
                                    <span className="small-icon material-symbols-rounded ">subdirectory_arrow_right</span></div>)}
                                <Link href={href}>
                                    {item.title}
                                </Link>
                            </div>
                        </li>
                    );
                })}
            </ul>
            <style jsx>
                {`
          nav {
            position: sticky;
            top: 0;
            height: calc(100vh - var(--top-nav-height) - 0px);
            max-width: 300px;
            min-width: 240px;
            flex-grow: 0;
            flex-shrink: 1;
            flex-basis: 20vw;
            align-self: flex-start;
            padding: 2.5rem 2rem 2rem 0rem;
            overflow: auto;
            margin-left: auto;
            width: 100%;
            z-index: 99;
          }
          nav .small-icon {
            font-size: var(--font-size-1);
            margin-right: 0.25rem;
          }
          .avatar-cont {
            margin-bottom: 1rem;
            margin-left: 1rem;
          }
          h3 {
            margin-left: 0.7rem;
            margin-right: -32px;
            font-size: var(--font-size-2);
            margin-left: 0;
            margin-top: 0;
            margin-bottom: 0.5rem;
            padding-left: 1rem;
            padding-top: 0px;
            padding-bottom: 0px;
            position: relative;
          }
          ul {
            margin: 0;
            padding: 0;
          }
          .column li .flex, h3{
            background: var(--background-glass-clear);
            padding: 0.25rem 0.5rem;
            border-radius: var(--border-radius);
            max-width: max-content;
          }
          li {
            list-style-type: none;
            padding-bottom: var(--default-vertical-spacing);
            font-size: var(--font-size-2);
            margin-left: 1rem;
            padding-bottom: 0.5rem;
          }
          li :global(a) {
            text-decoration: none;
          }
          li :global(a:hover),
          li.active :global(a) {
            text-decoration: underline;
          }
          li.padded {
            padding-left: 0rem;
          }

          @media screen and (max-width: 1200px) {
            nav {
              display: none;
            }
          }
        `}
            </style>
        </nav>
    );
}

