import { Flex } from "@radix-ui/themes";
import Feedback from "../Feedback";
import Footer from "../Footer";
import GlobalNav from "../GlobalNav";
import SideNav from "../SideNav";
import TableOfContents from "../TableOfContents";
import TopNav from "../TopNav";


export default function Layout(props) {

    const collectHeadings = (node, sections: any[] | undefined = []) => {
        if (node) {
    
            if (node.name === "Blogpage") {
                if (!node.blog) {
                    sections = undefined;
                    return;
                }
                if (node.blog?.value?.document?.children) {
                    for (const i of node.blog.value.document.children) {
                        if (i.type === "heading") {
                            sections.push({
                                level: i.level,
                                id: i.children[0].value.replace(/\s+/g, "-").toLowerCase(),
                                title: i.children[0].value
                            });
                        }
                    }
                }
            }
    
            if (node.name === "Heading") {
                const title = node.children[0];
    
                if (typeof title === "string") {
                    sections.push({
                        ...node.attributes,
                        title
                    });
                }
            }
    
            if (node.children) {
                for (const child of node.children) {
                    collectHeadings(child, sections);
                }
            }
        }
    
        return sections;
    };
    

    const toc = props.markdoc?.content
        ? collectHeadings(props.markdoc.content)
        : collectHeadings({
            name: "Blogpage",
            blog: props.blog?.content
        });
    const blog = props.markdoc?.content
        ? {
            legacy: true,
            author: props.markdoc?.frontmatter?.author
        }
        : {
            legacy: false,
            author: props.blog?.author
        };

    return (<>
        <div className={`top-nav ${"nav-dark-blue"}`}>
            <div className="ready">
                <GlobalNav user={props.user} mobileMenuIsOpen={props.mobileMenuIsOpen} setMobileMenu={props.setMobileMenu} />
            </div>
            <TopNav children={undefined}></TopNav>
        </div>
        <div id="main-wrapper" className={(props.mobileMenuIsOpen ? " menu-open " : "") + " doc " + " main-wrapper "}>
            <main className={"main " + (props.isLegacy && " blog-post-legacy")}>
                {!props.isLegacy && !props.isPolicy && <SideNav/>}
                {(props.isLegacy || props.isPolicy) && <div className="spacer-left"/>}
                <div className="inner">
                    <Flex direction="column" gap="3">
                        {props.children}
                        <Feedback/>
                    </Flex>
                </div>
                {props.isPolicy && <div className="spacer-right"/>}
                {props.isLegacy && <TableOfContents toc={toc} blog={blog}/>}
            </main>
            <div className="footer-bar">
                <Footer handleToast={props.handleToast} />
            </div>
        </div>
        <style jsx global>{`
            body{
                overflow: hidden;
                overflow-x:hidden;
            }
         
            .nav-divider{
                border-top: 1px solid var(--black-medium);
                border-left:300px solid transparent;
                border-right:300px solid transparent;
                background: var(--covalent-dark-blue);
              }
            .divider-cont{
                overflow:hidden;
                width:100%;

            }

            .top-nav:hover{
                background: var(--background);
            }

            .main-wrapper {
                overflow-x:hidden;
                max-height: calc(100vh - var(--top-nav-height));
            }
            .inner {
                max-width: 100vw;
            }

            .legacy-header {
                display: grid;
                grid-gap: 24px;
                gap: 24px;
                max-width: 54rem;
                width: 100%;
                min-width: 0;
                padding: 2rem 2rem 0rem 4rem;
                align-items: center;
            }
            @media screen and (max-width: 767px) {
                .legacy-header {
                    padding: 0rem 1rem;
                    margin-top: 1rem;
                }
                .apiref {
                    max-height: calc(100vh - var(--top-nav-height));
                    min-height: 0 !important;
                }
            }
            
            .date {
                font-size: var(--font-size-3);
                margin-top: -1rem;
                color: var(--pink);
            }

          .page {
            justify-content:center;
            min-height: calc(100vh - var(--top-nav-height));
          }
          .page main {
            display:flex;
          }

          .doc {
            width: 100vw;
            min-height: calc(100vh - var(--top-nav-height));
            max-width: 100%;
            
          }
          .doc main {
            display: flex;
          }

          .blog {
            width: 100%;
          }

          .apiref {
            width: 100vw;
            min-height: calc(100vh - var(--top-nav-height));
            max-width: 100%;
          }

          .page.menu-open {
            overflow: hidden;
            height: 100%;
          }

          .doc.menu-open {
            overflow: hidden;
            height: 100%;
          }

          .main{
           width: 100%;
          }

          article {
            padding: 2rem 1.5rem 3rem;
            
          }

          .spacer-left {
            margin-left: auto;
          }

          .spacer-right {
            margin-right: auto;
          }

        `}
        </style>
    </>);
}